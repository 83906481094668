.NavbarItems{
    background: rgb(139, 211, 255);;
    /* linear-gradient(90deg, rgb(110, 94, 254) 0%,rgba(73,63,252,1) 100%); */
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.navbar-logo{
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
}

.fa-react{
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.nav-menu{
    display: grid;
    grid-template-columns: repeat(4,auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: center;
    margin-right: 2rem;
}

.nav-links{
    color: black;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:hover {
    background-color: #80BDE3;
    border-radius: 4px;
    transition: all 0.2s ease-out;
}

