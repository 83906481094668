.Projects {
    position: relative;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  
.Projects-box{
  background: rgb(139, 211, 255);
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}

.Projects-img{
  width:  100px;
  height: 100px;
  transition: transform .2s;
  object-fit: cover;
  margin-top: 4rem;
}

.Projects-img:hover{
  transform: scale(1.05);
}

.Projects-Name{
  margin-top: 2rem;
}

.Projects-description{
  padding: 10px 20px;
}

  .Projects a,
  .Projects a:hover {
    text-decoration: none;
  }
  
  .Projects .card {
    color: black;
    margin-right: 1px;
    margin-bottom: 1rem;
    border-radius: 1rem;
  }
  
  .Projects .card:hover {
    opacity: .7;
  }
  
  .Projects .card-title {
    text-align: center;
    font-weight: bold;
    font-family: "Source Sans Pro", Helvetica, sans-serif;
  }
  
  .Projects .card-img-top {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  
  .Projects .card-full-width {
    max-width: 54rem;
  }
  
  .Projects .card-half-width {
    max-width: 27rem;
  }