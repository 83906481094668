.intro{
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
}

.i-left{
    margin-left: auto;
    margin-right: auto;
}

.i-right{
    flex: 1;
    display: grid;
    height:100%
}

.i-left-wrapper{
    padding: 50px;
}

.i-intro{
    font-size: 30px;
    font-weight: 300;
    text-align: center;
}

.i-name{
    font-size: 60px;
}

.i-title{
    font-size: 30px;
    font-weight: bold;
    color: rgb(139, 211, 255);
    text-align: center;
}

.i-image{
    display: block;
    height: 25%;
    width: 25%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    border: 2px solid rgb(139, 211, 255);
    border-style: bold;
    border-spacing: 50px;
    cursor: pointer;
}

.i-image:hover{
    -webkit-filter: brightness(70%);

}

.i-title-wrapper{
    height: 100%;
    /* uncomment when I have more in wrapper */
    /* animation: move 10s ease-in-out infinite alternate */
}

.i-description{
    font-size: 20px;
    text-align: center;
    margin-top: 25px;
    width: 60%;
    margin: 0 auto;
}

.btn-resume{
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    background-color:rgb(139, 211, 255);
    color: white;
}    

.logos{
    margin-left: 50px;
    margin-top: -50px;
    font-size: 40px;
}

.background-image {
    top: 10%;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1200px;
    z-index: -4;
  }





/* 
@keyframes move {
    25%{
        transform: translateY(-50px);
    }
    50%{
        transform: translateY(-100px);
    }
    75%{
        transform: translateY(-150px);
    }
    100%{
        transform: translateY(-200px);
    }
} */
